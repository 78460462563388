<template>
  <div class="user-subscriptions--history-table">
    <v-simple-table>
      <thead>
        <tr>
          <th>Membership Name</th>
          <th>Valid Until Date</th>
          <th>Status</th>
          <th>Purchase Type</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="hasMemberships && !loading">
          <tr
            v-for="membership in memberships"
            :key="membership.user_subscription.id"
            class="clickable"
            @click="goTo(membership)"
          >
            <td style="width: 30%">
              <h2 class="subtitle-1">
                <v-icon
                  color="green lighten-2"
                  v-if="membership.user_subscription.is_default"
                >
                  {{ icons.check }}
                </v-icon>
                {{ membership.name }}
              </h2>
            </td>

            <td>
              {{ membership.user_subscription.valid_until | formatIntakeDate }}
            </td>

            <td>
              <v-chip
                v-if="membership.user_subscription.deactivated_at"
                :color="'deactivated' | membershipStatusColor"
                dark
              >
                {{
                  membership.user_subscription.is_deactivated
                    ? 'Deactivated'
                    : 'For Deactivation'
                }}
              </v-chip>
              <v-chip
                v-else-if="membership.user_subscription.pause_status.length > 0"
                :color="'paused' | membershipStatusColor"
                dark
              >
                Paused
              </v-chip>
              <v-chip
                v-else
                :color="
                  membership.user_subscription.status | membershipStatusColor
                "
                text-color="white"
              >
                {{ membership.user_subscription.status | capitalize }}
              </v-chip>
            </td>
            <td>
              <v-chip
                :color="
                  membership.user_subscription.checkout_info.purchase_type
                    | membershipPurchaseTypeColor
                "
                dark
              >
                <span class="text-capitalize">
                  {{
                    membership.user_subscription.checkout_info.purchase_type
                      | membershipPurchaseType
                  }}
                </span>

                <span
                  class="font-weight-bold"
                  v-if="
                    membership.user_subscription.checkout_info.purchase_type ===
                      'trial'
                  "
                >
                  : Valid until
                  {{
                    membership.user_subscription.checkout_info
                      .purchase_valid_until | formatDate('MMMM DD, YYYY')
                  }}
                </span>
              </v-chip>
            </td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list v-if="!hasMemberships && !loading" details="No Memberships" />
    <confirm-dialog ref="confirmDeactivation" />
    <v-toast ref="toast" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import dayjs from 'dayjs'
import NoList from '@/components/elements/NoList'
import { mdiCheckCircle } from '@mdi/js'
import { mapActions } from 'vuex'
import { values, flattenDeep } from 'lodash'
import VToast from '@/components/elements/Toast'

export default {
  name: 'MembershipHistoryTable',

  components: {
    NoList,
    TableSkeletonLoader,
    ConfirmDialog,
    VToast,
  },

  data() {
    return {
      deactivateLoading: false,
      defaultingLoading: false,
      icons: {
        check: mdiCheckCircle,
      },
    }
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    memberships: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  computed: {
    hasMemberships() {
      return !!this.memberships.length
    },
  },

  filters: {
    membershipStatusColor(status) {
      if (status === 'active') return 'primary'
      if (status === 'paused') return 'green'
      if (status === 'completed') return 'green'
      if (status === 'deactivated') return 'red'

      return 'orange'
    },

    membershipPurchaseTypeColor(type) {
      if (type === 'iap') return 'blue'
      if (type === 'trial') return 'grey'
      if (type === 'shopify') return 'primary'

      return 'teal' // For manual assignment
    },

    membershipPurchaseType(type) {
      if (type === 'iap') return 'In App'

      return type
    },

    formatIntakeDate(value) {
      if (!value) {
        return ''
      }

      return dayjs.utc(value).format('MMM DD, YYYY')
    },
  },

  methods: {
    ...mapActions({
      deactivateUserMembership: 'userProfile/deactivateUserMembership',
      assignDefaultMembership: 'users/assignDefaultMembership',
    }),

    goTo(membership) {
      this.$emit('goTo', membership.user_subscription.id)
    },

    isMembershipActive(membership) {
      return membership.user_subscription.status === 'active'
    },

    async deactivateMembership(membership) {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Deactivate Membership',
        'Are you sure you want to deactivate this membership for this user?'
      )

      if (!confirm) return

      this.deactivateLoading = true

      try {
        await this.deactivateUserMembership({
          userId: this.$route.params.id,
          userSubscriptionId: membership.user_subscription.id,
        })

        setTimeout(() => {
          this.deactivateLoading = false
          membership.user_subscription.status = 'deactivated'
          membership.user_subscription.is_default = false
          this.$emit('reloadMembershipHistory')
        }, 900)
      } catch (err) {
        this.deactivateLoading = false
      }
    },

    async setDefault(membership) {
      this.defaultingLoading = true
      try {
        await this.assignDefaultMembership({
          userId: this.$route.params.id,
          userSubscriptionId: membership.user_subscription.id,
        })

        this.$refs.toast.open({
          color: 'primary',
          message: 'Successfully assign new default membership.',
        })
        this.defaultingLoading = false
        this.isDefault = true
        this.$emit('reloadMembershipHistory')
      } catch (err) {
        this.defaultingLoading = false

        if (err) {
          if (err.response.data !== undefined) {
            this.$refs.toast.open({
              color: 'red',
              timeout: 3000,
              message: flattenDeep(values(err.response.data.errors)).join(' '),
            })
          }
        }
      }
    },
  },
}
</script>
