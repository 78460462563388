<template>
  <v-dialog
    v-model="show"
    max-width="650"
    class="assign-new-user-membership-modal"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>
        <h4 class="mb-1">Add New User Membership</h4>
      </v-card-title>

      <v-divider class="mb-5" />

      <v-card-text class="my-3">
        <search-memberships
          v-model="form.subscription"
          :disabled="form.$busy"
          :error-messages="form.$getError('subscription')"
          :hide-details="!form.$getError('subscription')"
          label="Search Memberships"
          @change="membershipSelected"
          return-object
          is-for-filter
          clearable
          outlined
          flat
        />
      </v-card-text>

      <v-card-text v-if="form.subscription">
        <v-select
          v-model="form.variant"
          :items="variants"
          :disabled="form.$busy"
          :error-messages="form.$getError('variant_id')"
          label="Membership Variants"
          item-text="description"
          item-value="id"
          outlined
          flat
        />
      </v-card-text>

      <v-divider class="mb-3" />

      <v-card-actions class="px-5 pb-5">
        <v-spacer />

        <v-btn color="warning" :loading="form.$busy" @click="show = false" text>
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          :loading="form.$busy"
          :disabled="!form.subscription"
          @click="addNewMembership"
          text
        >
          Add New Membership
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchMemberships from '@/components/elements/memberships/SearchMembershipsInput'
import Form from '@/utils/form'

import { mdiTrashCanOutline } from '@mdi/js'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AssignNewMembership',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    SearchMemberships,
  },

  data() {
    return {
      variants: [],
      show: this.value,
      icons: {
        remove: mdiTrashCanOutline,
      },
      form: new Form({
        is_default: false,
        user_id: this.$route.params.id,
        variant: null,
        subscription: null,
      }),
    }
  },

  methods: {
    ...mapActions({
      assignNewUserMembership: 'users/assignNewUserMembership',
    }),

    ...mapMutations({
      attachNewMembership: 'userProfile/attachNewMembership',
    }),

    membershipSelected(membership) {
      if (membership) {
        this.variants = membership.variants
      } else {
        this.errors = {}
      }
    },

    async addNewMembership() {
      this.form.$busy = true
      this.form.$clearErrors()

      await this.assignNewUserMembership({
        userId: this.$route.params.id,
        membershipId: this.form.subscription.id,
        variantId: this.form.variant,
      })
        .then(({ data }) => {
          setTimeout(() => {
            this.form.$busy = false
            this.setData(data.data)
            this.show = false
            this.$emit('reloadMembershipHistory')
          }, 600)
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.form.$busy = false

            if (response && response.status === 403) {
              this.form.$setErrors({ membership: [response.data.message] })
            }

            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
          }, 600)
        })
    },

    setData(data) {
      this.attachNewMembership(data)
    },
  },

  watch: {
    value(val) {
      this.show = val
    },

    show(val) {
      this.$emit('input', val)

      if (!val) {
        this.errors = {}
        this.form.membership = null
        this.form.variant = null
      }
    },
  },
}
</script>
