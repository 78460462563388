<template>
  <v-autocomplete
    v-model="memberships"
    v-bind="$attrs"
    :items="selectedMemberships"
    :label="label"
    :placeholder="placeholder"
    :search-input.sync="search"
    :error-messages="errorMessages"
    item-text="name"
    item-value="id"
  >
    <template v-if="isMultiple" v-slot:selection="{ item, index }">
      <div v-if="isForFilter">
        <v-chip
          v-if="index <= 0"
          v-bind="item.attrs"
          @click:close="removeMembership(item.index)"
          close
        >
          <span>{{ item.name }}</span>
        </v-chip>

        <span v-if="index === 1" class="grey--text caption ml-2">
          (+{{ value.length - 1 }} others)
        </span>
      </div>

      <v-chip
        v-else
        class="mt-3 text-capitalize"
        v-bind="item.attrs"
        @click:close="removeMembership(item.index)"
        close
      >
        {{ item.name }}
      </v-chip>
    </template>

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="d-flex align-center">
          <span> {{ item.name }} </span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Membership from '@/models/Membership'
import { filter, debounce, uniqBy } from 'lodash'

export default {
  name: 'SearchMembershipsInput',

  props: {
    value: {
      type: [Array, Object],
    },

    type: {
      type: [String, Number],
    },

    label: {
      type: String,
      default: 'Memberships',
    },

    placeholder: {
      type: String,
      default: 'Search for Membership',
    },

    errorMessages: {
      type: [Object, Array],
    },
  },

  data() {
    return {
      search: null,
      selectedMembership: null,

      memberships: [],
      searchedMemberships: [],
    }
  },

  computed: {
    selectedMemberships() {
      return Array.isArray(this.memberships)
        ? uniqBy([...this.memberships, ...this.searchedMemberships], 'id')
        : uniqBy(
            filter(
              [this.memberships, ...this.searchedMemberships],
              (prog) => prog
            ),
            'id'
          )
    },

    hasSearch() {
      return !!this.search
    },

    isMultiple() {
      return 'multiple' in this.$attrs
    },

    isForFilter() {
      return 'is-for-filter' in this.$attrs
    },
  },

  created() {
    this.memberships = this.value
  },

  methods: {
    searchForMemberships: debounce(function() {
      this.fetchMemberships().then(({ data }) => {
        this.searchedMemberships = data
      })
    }, 800),

    async fetchMemberships() {
      let query = await Membership.where('search', this.search)
        .include('variants')
        .where('completed', true)
        .params({ limit: 100 })

      return query.get()
    },

    removeMembership(index) {
      this.memberships.splice(index, 1)
    },
  },

  watch: {
    search(key) {
      if (key) {
        this.searchForMemberships()
      }
    },

    memberships(values) {
      this.$emit('input', values)
      this.$emit('change', values)
    },

    value(values) {
      this.memberships = values
    },
  },
}
</script>
