<template>
  <div class="user-profile--memberships-list">
    <v-row>
      <v-col cols="12" sm="12" md="10" class="d-flex">
        <v-btn
          class="bg-primary-gradient primary ml-auto"
          @click="addNewMembership = true"
          @reloadMembershipHistory="reloadMembershipHistory"
        >
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Assign New Membership
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10">
        <membership-history-table
          :memberships="memberships"
          :loading="loading"
          ref="membershipHistoryTable"
          @goTo="goTo"
          @reloadMembershipHistory="reloadMembershipHistory"
        />
      </v-col>
    </v-row>

    <assign-new-membership
      v-model="addNewMembership"
      @reloadMembershipHistory="reloadMembershipHistory"
    />
  </div>
</template>

<script>
import AssignNewMembership from '@/components/modals/users/AssignNewMembership'
import MembershipHistoryTable from '../components/MembershipHistoryTable'

import { mdiPlus } from '@mdi/js'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'UserMembershipList',

  components: {
    AssignNewMembership,
    MembershipHistoryTable,
  },

  data() {
    return {
      loading: true,
      addNewMembership: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  created() {
    this.fetchMemberships()
  },

  destroyed() {
    this.clearMemberships()
  },

  computed: {
    ...mapState({
      memberships: (state) => state.userProfile.memberships,
    }),
  },

  methods: {
    ...mapActions({ getMemberships: 'userProfile/getMemberships' }),
    ...mapMutations({ clearMemberships: 'userProfile/clearMemberships' }),

    async fetchMemberships() {
      await this.getMemberships(this.$route.params.id)

      await this.$sleep()

      this.loading = false
    },

    goTo(userSubscriptionId) {
      this.$router.push({
        name: 'user.membership-history.details',
        params: { userSubscriptionId },
      })
    },

    reloadMembershipHistory() {
      this.$router.go()
    },
  },
}
</script>
